import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import en from './lang/en'
import fr from './lang/fr'
import es from './lang/es'
import * as Sentry from "@sentry/vue";

Vue.use(Buefy)

Vue.config.productionTip = false

const messages = {
  en: {
    ...en
  },
  fr: {
    ...fr
  },
  es: {
    ...es
  }
}
console.log(messages)
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages,
});

Sentry.init({
  Vue,
  dsn: "https://615918cc1882766ab3ab281e07476781@o395072.ingest.sentry.io/4505911566663680",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", 'dev-getinshoot-recorder.firebaseapp.com', 'dev-getinshoot-recorder.web.app', 'r.getin.sh', 'getinshootrecorder.web.app'],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  logErrors: true,
  environment: process.env.VUE_APP_ENV_NAME
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
