<template>
  <section style="padding-top: 35px">
    <div style="margin: 0 auto" class="tile is-ancestor">
      <div class="tile is-12" style="justify-content: center">
        <div class="tile is-5 is-vertical">
          <article class="tile is-child notification" style="
              background-color: white;
              display: flex;
              flex-direction: column;

              color: #757575 !important;
              border-radius: 20px 20px 0 0;
              padding-top: 50px;
              padding-left: 50px;
              padding-right: 50px;
            ">
            <!-- STEP 1 -->
            <div class="step-container" id="step1">
              <div style="padding-top: 15px">
                <h2 style="margin-bottom: 15px; text-align: center">
                  {{ $t("howRate") }}
                </h2>
                <br />
                <div
                  style="display: flex;justify-content: center; align-items: center;  padding: 10px; border-radius: 10px; background-color: aliceblue;">

                  <figure class="image is-64x64">
                    <img style="border-radius: 5px; " :src="metadatas.purchaseCoverUrl &&
                        metadatas.purchaseCoverUrl !== ''
                        ? metadatas.purchaseCoverUrl
                        : '../../src/assets/tshirt.jpg'
                      " />
                  </figure>


                  <p style="margin-left: 20px;">{{ metadatas.purchaseTitle }}</p>

                </div>
              </div>
              <br />
              <div v-if="rate == null" class="rating-btn_container" style="align-items: center; flex-direction: column"
                @click="skipStep()">
                <b-rate v-model="rate" size="is-large" :disabled="!changeStars" style="margin: 0 !important"></b-rate>
                <small class="rating-btn" v-if="!changeStars" @click="changeStars = true">
                </small>
                <div style="
                    display: flex;
                    gap: 20px;
                    width: 256px;
                    justify-content: space-between;
                  ">
                  <p style="color: black">{{ $t("dontLike") }}</p>
                  <p style="color: black">{{ $t("like") }}&nbsp;&nbsp;</p>
                </div>
              </div>
              <div v-else class="rating-btn_container" style="align-items: center; flex-direction: column"
                @click="skipStep()">
                <b-rate v-model="rate" size="is-large" :disabled="!changeStars" style="margin: 0 !important"></b-rate>
                <small class="rating-btn" v-if="!changeStars" @click="changeStars = true">
                </small>
                <div style="
                    display: flex;
                    gap: 20px;
                    width: 256px;
                    justify-content: space-between;
                  ">
                  <p style="color: black">{{ $t("dontLike") }}</p>
                  <p style="color: black">{{ $t("like") }}&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>

            <!-- STEP 2 -->
            <div class="step-container" id="step2" style="display: none">
              <div style="padding-top: 15px">
                <h2 style="margin-bottom: 15px">
                  {{ $t("addComment") }}
                </h2>
                <p>{{ $t("addCommentBaseline") }}</p>
              </div>
              <br />

              <b-field>
                <b-input v-model="comment" maxlength="300" type="textarea" has-counter></b-input>
              </b-field>

            </div>

            <!-- STEP 3 -->
            <div class="step-container" id="step3" style="display: none">
              <div style="padding-top: 15px">
                <h2 style="margin-bottom: 15px">
                  {{ $t("addPicture") }}
                </h2>
                <p>{{ $t("addPictureBaseline") }}</p>
              </div>
              <br />
              <div>
                <b-field v-if="!dropFiles || (dropFiles && dropFiles.length < 3)">
                  <b-upload v-model="dropFiles" multiple expanded accept=".png,.PNG,.jpeg,.jpg,.JPEG,.JPG,.AVIF,.avif"
                    drag-drop style="background-color: #f0f0f0; margin: 0px" @input="checkMaxFiles">
                    <section class="section" style="padding: 15px !important">
                      <div class="content has-text-centered">
                        <img style="height: 60px" src="../assets/addphotos.png" alt="ArrowBack" />
                        <p style="font-weight: bold; color: #1b1b38">
                          {{ $t("addMax3Pictures") }}
                        </p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
                <div class="tags">
                  <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
                    {{ file.name }}
                    <button class="delete is-small" type="button" @click="deleteDropFile(index)">
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <!-- STEP 4 -->
            <div :class="[
              'step-container',
              { 'step-container-large': showRecorder },
            ]" id="step4" style="display: none">
              <div style="padding-top: 15px">
                <h2 style="margin-bottom: 15px">
                  {{ !metadatas.sellerHasIncentive && incentive == 'text' ? $t("addVideo") : $t("addVideoWithout") }}
                </h2>
                <p>{{ !metadatas.sellerHasIncentive && incentive == 'text' ? $t("addVideoBaseline") :
                  $t("addVideoBaselineWithout") }}</p>
              </div>
              <br />
              <div v-if="!showRecorder" style="
                  text-align: center;
                  padding: 21px;
                  background-color: #f0f0f0;
                  border-radius: 5px;
                  border: 1px dashed #b8b8b8 !important;
                " @click="showRecorder = true">
                <img style="height: 60px" src="../assets/addvideos.png" alt="ArrowBack" />
                <p style="font-weight: bold; color: #1b1b38">
                  {{ $t("recordVideo") }}
                </p>
              </div>
              <Recorder v-if="showRecorder" :metadatas="metadatas"></Recorder>
            </div>
          </article>
        </div>
      </div>
    </div>

    <!-- NAVIGATION BOTTOM  -->
    <div style="
        background-color: white;
        margin: 0 auto;
        height: 60px;
        padding: 50px 25px 25px 25px;
        border-radius: 0 0 20px 20px;
      " class="tile is-5">
      <div class="tile" style="
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 60px !important;
          width: 100%;
        ">
        <!-- Bouton retour ou espace réservé invisible -->
        <div style="flex: 0 1 20%">
          <button id="stepBackButton" @click="stepBack()" class="tile" style="
              display: none;
              align-items: center;
              cursor: pointer;
              background: none;
              border: none;
            ">
            <img style="height: 15px !important; margin-right: 10px" src="../assets/arrowback.png" alt="ArrowBack" />
            <p style="color:#1b1b38 !important">{{ $t("retour") }}</p>
          </button>
        </div>

        <!-- StepsDot -->
        <div id="stepsDot" style="
            display: none;
            gap: 5px;
            flex: 0 1 60%;
            justify-content: center;
          ">
          <div style="
              background-color: #1b1b38;
              border-radius: 5px;
              height: 7px;
              width: 30px;
            ">
            &nbsp;
          </div>
          <div id="stepsDot2" style="
              background-color: #1b1b38;
              border-radius: 5px;
              height: 7px;
              width: 30px;
            ">
            &nbsp;
          </div>
          <div id="stepsDot3" style="
              background-color: #f0f0f0;
              border-radius: 5px;
              height: 7px;
              width: 30px;
            ">
            &nbsp;
          </div>
          <div id="stepsDot4" style="
              background-color: #f0f0f0;
              border-radius: 5px;
              height: 7px;
              width: 30px;
            ">
            &nbsp;
          </div>
        </div>

        <!-- Bouton sauter ou espace réservé invisible -->
        <div style="flex: 0 1 20%">
          <button id="skipStepButton" @click="skipStep()"
            :disabled="currentStep == 'step2' && (!comment || comment == '')" class="tile stepButton" style="
              display: none;
              align-items: center;
              justify-content: flex-end;
              cursor: pointer;
              border: none;
              background: none;
            
            ">
            <p>{{ currentStep == "step3" && (!dropFiles || dropFiles.length == 0) ? $t('ignorer') : $t("suivant") }}</p>
            <img style="height: 15px !important; margin-left: 10px"
              v-if="!(currentStep == 'step2' && (!comment || comment == ''))" src="../assets/arrowskip.png"
              alt="ArrowBack" />
          </button>
          <!-- Espace réservé invisible pour maintenir l'équilibre lorsqu'un bouton est masqué -->
          <div style="visibility: hidden; flex: 1"></div>
        </div>
      </div>
    </div>

    <!-- FOOTER </p> -->
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
      ">
      <img style="width: 160px" src="../assets/powered_recreviews.png" alt="Logo Reviews" />
    </div>
  </section>
</template>

<script>
import Recorder from "./recorder.vue";

export default {
  name: "landing",
  components: {
    Recorder,
  },
  props: {
    metadatas: {
      type: Object,
      default: () => ({}),
    },
    incentive: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rate: this.metadatas.stars,
      changeStars: true,
      showRecorder: false,
      comment: this.metadatas.comment,
      currentStep: "step1",
      dropFiles: null
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    checkMaxFiles() {
      this.dropFiles = this.dropFiles.slice(0, 3)
    },
    saveRate() {
      this.$store.dispatch("updateStatus", 2);
      this.$store.dispatch("updateStars", this.rate);
    },
    saveComment() {

      this.$store.dispatch("updateComment", this.comment);
    },
    saveImages() {
      this.$store.dispatch("updateImages", this.dropFiles);
    },
    skipStep() {
      const currentStep = ["step1", "step2", "step3", "step4"].find((step) => {
        return document.getElementById(step).style.display !== "none";
      });
      switch (currentStep) {
        case "step1":
          this.saveRate();
          break;
        case "step2":
          this.saveComment();
          break;
        case "step3":
          if (this.dropFiles && this.dropFiles.length > 0) {
            this.saveImages();
          }
          this.$store.dispatch("updateStatus", 4);
          break;
        case "step4":
          break;
      }
      let nextStepIndex = (currentStep ? parseInt(currentStep.slice(-1)) : 0) + 1;
      const nextStep = "step" + nextStepIndex;
      this.currentStep = nextStep;
      this.toggleBackButton(nextStepIndex);
      this.toggleSkipButton(nextStepIndex);
      this.toggleStepDisplay(nextStep);
    },
    stepBack() {
      const currentStep = ["step1", "step2", "step3", "step4"].find((step) => {
        return document.getElementById(step).style.display !== "none";
      });
      let prevStepIndex = (currentStep ? parseInt(currentStep.slice(-1)) : 0) - 1;
      const prevStep = "step" + prevStepIndex;
      this.currentStep = prevStep;
      this.toggleBackButton(prevStepIndex);
      this.toggleSkipButton(prevStepIndex);
      this.toggleStepDisplay(prevStep);
      this.showRecorder = false;
    },
    toggleBackButton(stepIndex) {
      // Cacher le bouton "retour" et les "stepdots" au step 1
      if (stepIndex === 1) {
        document.getElementById("stepBackButton").style.display = "none";
        document.getElementById("stepsDot").style.display = "none";
        return;
      }

      // Afficher le bouton "retour" et réinitialiser la couleur des "stepdots" pour les autres étapes
      document.getElementById("stepBackButton").style.display = "flex";
      document.getElementById("stepsDot").style.display = "flex";
      for (let i = 3; i <= 4; i++) {
        document.getElementById("stepsDot" + i).style.backgroundColor = "#f0f0f0";
      }

      // Définir la couleur des "stepdots" pour les étapes précédentes
      for (let i = 3; i < stepIndex; i++) {
        document.getElementById("stepsDot" + i).style.backgroundColor = "#1b1b38";
      }
    },

    toggleSkipButton(stepIndex) {

      // Cacher les "stepdots" et le bouton au step 1
      if (stepIndex === 1) {
        document.getElementById("stepsDot").style.display = "none";
        document.getElementById("skipStepButton").style.display = "none";
      } else {
        document.getElementById("stepsDot").style.display = "flex";
        document.getElementById("skipStepButton").style.display = "flex";

        // Changer le texte du bouton au step 3
        // const skipButtonText = document.getElementById("skipStepButton").querySelector("p");
        // if (stepIndex === 3) {
        //   skipButtonText.textContent = this.$t("ignorer");
        // } else {
        //   skipButtonText.textContent = this.$t("suivant");
        // }
      }
      
      if (stepIndex === 3) {
        document.getElementById("stepsDot3").style.backgroundColor = "#1b1b38";
      }

      // Logique pour le bouton "skip" et les "stepdots" pour les autres étapes
      if (stepIndex === 4) {
        document.getElementById("skipStepButton").style.display = "none";
        document.getElementById("stepsDot4").style.backgroundColor = "#1b1b38";
      }
    },

    toggleStepDisplay(step) {
      ["step1", "step2", "step3", "step4"].forEach((s) => {
        document.getElementById(s).style.display = s === step ? "block" : "none";
      });
    },
  },

  watch: {
    //rate(value) {
    //console.log("Rate changed to:", value);
    //this.$store.dispatch("updateStars", value);
    //this.skipStep();
    //},
  },
};
</script>

<style src="./index.css"></style>
