<template>
  <section style="background-color: white; border-radius: 20px">
    <div style="margin: 0 auto" class="tile is-ancestor">
      <div class="tile is-vertical is-12">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article
              class="tile is-child notification"
              style="background-color: transparent; color: #757575 !important"
            >
            <div class="" style="text-align: center;">
                      <img
          v-if="$store.state.metadatas.sellerLogo && $store.state.metadatas.sellerLogo != ''"
          style="
            width: 250px;
          "
          :src="$store.state.metadatas.sellerLogo"
          alt="Logo Merchant"
        />
        <div
          v-else
        ></div> 
      </div>
            <br />
              <p
                v-html="$store.state.terms[$i18n.locale]"
                style="color: #372774; text-align: center"
              ></p>

              <br />
              <div class="has-text-centered">
                <b-button
                  style="
                    background-color: #12c477;
                    border: none;
                    color: white;
                    font-weight: bold;
                  "
                  size="is-default"
                  @click="$emit('confirmTerms')"
                  >{{ $t("confirm") }}</b-button
                >
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "terms",
  props: {
   
  },
  data() {
    return {};
  },
};
</script>

<style src="./index.css">
</style>