<template>
    <section>
        <div style="margin: 0 auto; max-width: 400px;" class="tile is-ancestor is-vertical">
            
            <div class="tile is-vertical is-12">
                <div class="tile">
                    <div class="tile is-parent" style="justify-content: center;"
                        v-if="!isChromeIOS && !noDevicesFound">
                        <article class="tile is-child recorder-card_container">
                            
                            <div>
                                <div class="recorder-header">
                                    <div class="recorder-header_timer"
                                        style="margin-bottom: 1em;margin-top: 1em;height: fit-content;">
                                        <span v-if="!shootDone && type == 'video'" class="timer">
                                            {{ remainingDuration }}
                                        </span>
                                    </div>
                                </div>
                                <div class="videoContainer" ref="videoContainer" id="videoContainer" v-if="!uploading">
                                    <video id="recorderVideo" v-if="!shootDone" style="margin: auto" playsinline
                                        class="video-js vjs-default-skin .vjs-1-1"></video>
                                </div>
                                <div v-else
                                    style="background-color: #ffffff !important; padding: 25px; border-radius: 10px; margin: 0 auto !important;">
                                    <div style="display: flex; justify-content: center;">
                                        <span>{{ $t('waitUpload') }}</span>
                                    </div>
                                    <b-progress type="is-info" :max="100" :value="progress" show-value size="is-medium"
                                        style="margin: 20px">
                                    </b-progress>
                                </div>
                            </div>
                            <footer v-if="shootSuccess" class="recorder-footer"></footer>
                            <footer v-if="shootDone" class="">
                                <div class="tile is-vertical"> 
                                    <div class="tile" style="    display: flex;
    justify-content: space-around;"><p class="">
                                    <span>
                                        <b-button
                                            style="background-color: #3457dd;color: white;font-weight: bold;border: none;"
                                            :disabled="uploading" size="is-default" @click="reset()">{{ $t("retry")
                                            }}</b-button>
                                    </span>
                                </p>
                                <p class="">
                                    <span>
                                        <b-button
                                            style="background-color: #12c477;color: white;font-weight: bold;border: none;"
                                            size="is-default" :disabled="uploading" @click="uploadContrib()">{{ $t("valider")
                                            }}</b-button>
                                    </span>
                                </p></div>
                               <br>
                                <p style="font-size: 11px;">{{ $t("consent")
                                            }}</p>
                                    </div>
                               
                                
                            </footer>
                            <footer v-else class="recorder-footer">
                                <p class="recorder-footer-item">
                                    <span>
                                        <figure v-if="isRecordingVideo"></figure>
                                        <figure v-else>
                                            <img @click="isModalSettings = true"
                                                style="width: 40px;margin-top: 20px;cursor: pointer !important;"
                                                src="../../src/assets/setting.png" />
                                        </figure>
                                    </span>
                                </p>
                                <b-modal v-model="isModalSettings" :width="500" scroll="keep">
                                    <Settings :devices="devices" :audioDeviceId="audioDeviceId" :videoDeviceId="deviceId"
                                        v-on:updateInputDevices="updateDevices" />
                                </b-modal>
                                <p class="recorder-footer-item">
                                    <span>
                                        <figure v-show="!isRecordingVideo &&
                                            type == 'video' &&
                                            !isCountdownInProgress
                                            ">
                                            <div @click="record()">
                                                <div :class="{ 'recorder-btn': true, active: isActive }"
                                                    @click="toggleActive">
                                                    <div class="inner-recorder-btn"></div>
                                                </div>
                                            </div>
                                        </figure>
                                        <figure v-show="isRecordingVideo && type == 'video'">
                                            <div @click="stop()">
                                                <div :class="{ 'recorder-btn': true, active: isActive }"
                                                    @click="toggleActive">
                                                    <div class="inner-recorder-btn"></div>
                                                </div>
                                            </div>
                                        </figure>
                                        <figure v-show="!isRecordingVideo &&
                                            type == 'video' &&
                                            isCountdownInProgress
                                            ">
                                            <div @click="stop()">
                                                <div :class="{ 'recorder-btn': true, active: isActive }"
                                                    @click="toggleActive">
                                                    <div class="inner-recorder-btn"></div>
                                                </div>
                                            </div>
                                        </figure>
                                    </span>
                                </p>
                     
                                <p class="recorder-footer-item">
                                    <span>
                                        <figure v-if="!isRecordingVideo && isMobile()">
                                            <img @click="swipeCamera()"
                                                style="width: 40px;margin-top: 20px;cursor: pointer !important;"
                                                src="../../src/assets/reverse.png" />
                                        </figure>
                                    </span>
                                </p>
                            </footer>
                        </article>
                    </div>
                    <b-modal v-model="noDevicesFound" :can-cancel="false">
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">
                                    {{ $t("noDevicesFound") }}
                                </p>
                            </header>
                            <section class="modal-card-body">
                                <div class="content">
                                    <p>{{ $t("noDevicesFoundMessage") }}</p>
                                </div>
                            </section>
                            <footer class="modal-card-foot">
                                <b-button style="background-color: #372774;color: white;font-weight: bold;"
                                    @click="$router.go(0)">{{ $t("refresh") }}</b-button>
                            </footer>
                        </div>
                    </b-modal>
                    <b-modal v-model="isChromeIOS" full-screen :can-cancel="false">
                        <br /><br />
                        <p class="notification"
                            style="font-weight: bold;font-size: 14px;background-color: #f9664c;color: white !important;">
                            {{ $t("errorNavigator") }}
                            <br />
                            <br />
                            <b-button @click="$router.go(-1)" size="is-small"
                                style="background-color: white;color: #f9664c;border-radius: 5px !important;">
                                {{ $t("back") }}
                            </b-button>
                        </p>
                        <br />
                        <br />
                    </b-modal>
                    <!-- <b-modal v-model="isCameraAccessRequired" full-screen :can-cancel="false">
                        <div style="min-height: 100vh; background-color: #3457dd;"><br /><br />
                            <img class="arrowDown" style="width: 40px;" src="../assets/arrowAlertCamera.png" />
                            <div class="box" style="width: 600px; margin: 0 auto">
                                <p style="font-weight: bold;text-align: center;font-size: 18px;color: #161032 !important;">
                                     {{ $t("autorisation") }}
                                    <br />
                                </p>
                            </div>
                        </div>
                    </b-modal> -->
                    <b-modal v-model="isCameraError" full-screen :can-cancel="false">
                        <div style="min-height: 100vh; background-color: #3457dd;"><br /><br />
                            <img class="arrowTop" style="width: 40px;" src="../assets/arrowAlertCamera.png" />
                            <div class="box" style="width: 400px; margin: 0 auto">
                                   <p style="font-weight: bold;text-align: center;font-size: 18px;color: #161032 !important;">
                                    <br />
                                    {{ $t("autorisation") }}
                                    <br /> <br />
                                    <span class="material-symbols-outlined">
                                        videocam_off
                                    </span> <span class="material-symbols-outlined">
                                        mic_off
                                    </span> <br />
                                </p>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import Settings from "./settings.vue";
import Success from "./success.vue";
import Terms from "./terms.vue";
/* eslint-disable */

import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";

export default {
    name: "recorder",
    components: {
        Settings,
        Success,
        Terms,
    },
    props: {
        type: {
            type: String,
            default: "video",
        },
        metadatas: {
            type: Object,
            default: () => ({}),
        },
        terms: {
            type: Object,
            default: () => ({}),
        },
    },

    filters: {
        date: (value) => {
            if (!value) return "";
            return new Date(value).toLocaleDateString();
        },
    },
    data() {
        return {
            noDevicesFound: false,
            isChromeIOS: false,
            isCameraError: false,
            isCameraAccessRequired: false,
            isImageModalActive: false,
            remainingDuration: "--:--",
            shootDone: false,
            uploading: false,
            progress: 0,
            isModalSettings: false,
            shootSuccess: false,
            isRecordingVideo: false,
            file: null,
            player: "",
            devices: [],
            deviceId: "default",
            audioDeviceId: "default",
            isCardModalActive: false,
            rate: this.metadatas.stars,
            loading: false,
            changeStars: false,
            isActive: false,
            isCountdownStopped: false,
            isCountdownInProgress: false,

            options: {
                // video.js options
                controls: false,
                bigPlayButton: false,
                autoplay: true,
                fluid: true,
                width: 1920,
                height: 1080,
                aspectRatio: "1:1",
                controlBar: {
                    volumePanel: true,
                    fullscreenToggle: false,
                    recordIndicator: false,
                    cameraButton: false,
                    recordToggle: false,
                    pipToggle: false,
                    deviceButton: false,
                },
                plugins: {
                    // videojs-record plugin options
                    record: {
                        image: false,
                        audio: true,
                        video: {
                            // video media constraints: set resolution of camera
                            width: {
                                min: 640,
                                ideal: 1920,
                                max: 1920
                            },
                            height: {
                                min: 480,
                                ideal: 1080,
                                max: 1080
                            },
                            frameRate: {
                                ideal: 25,
                                max: 30
                            },
                            aspectRatio: 1.7777777778,
                            facingMode: "user",
                        },
                        maxLength: 500,
                        frameWidth: 1920,
                        frameHeight: 1080,
                        timeSlice: 100,
                        imageOutputFormat: "image/png",
                        imageOutputQuality: 0.92,
                        imageOutputType: "dataURL",
                        displayMilliseconds: true,
                        debug: false,
                    },
                },
            },
        };
    },
    watch: {
        rate(value) {
            this.changeStars = false;
            this.$store.dispatch("updateStars", value);
            this.$buefy.toast.open({
                message: this.$t('rateModif'),
                type: "is-warning",
                position: "is-top-right",
            });
        },
    },
    methods: {

        play() {
            this.player.controls(true);
            this.player.play();
        },

        isMobile() {
            return window.innerWidth < 600;
        },
        createCountdownElement(playerDiv) {
            const countdownElement = document.createElement("div");
            countdownElement.classList.add("countdown");
            recorderVideo.appendChild(countdownElement);
            return countdownElement;
        },

        startCountdown(countdownElement, countdownTime, onFinish) {
            console.log("startCountdown() called");
            this.isCountdownInProgress = true;
            let remainingTime = countdownTime;

            const updateCountdown = () => {
                console.log("Updating countdown:", remainingTime);
                countdownElement.textContent = remainingTime;
                remainingTime--;

                if (remainingTime < 0 || this.isCountdownStopped) {
                    onFinish(this.isCountdownStopped);
                } else {
                    setTimeout(updateCountdown, 1000);
                }
            };

            updateCountdown();
        },
        stopCountdown() {
            this.isCountdownStopped = true;
        },

        record() {
            console.log("record() called");
            const startRecording = () => {
                if (this.type == "video") {
                    this.player.record().start();
                    this.isRecordingVideo = true;
                } else {
                    // create new canvas
                    var canvas = document.createElement("canvas");
                    let width = window.innerWidth;
                    let height = window.innerHeight;
                    if (width > height) {
                        canvas.width = 1920;
                        canvas.height = 1080;
                    } else {
                        canvas.width = 1080;
                        canvas.height = 1920;
                    }

                    this.shootDone = true;
                    // draw video frame
                    var context = canvas.getContext("2d");
                    context.drawImage(
                        this.player.record().mediaElement,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    );
                    var target = new Image();
                    target.src = canvas.toDataURL();
                    target.id = "imgresult";
                    if (width < height) {
                        target.style.width = "100%";
                        target.style.height = "auto";
                        target.style.top = "50%";
                        target.style.left = "0";
                        target.style.transform = "translate(0, -50%)";
                    } else {
                        target.style.width = "auto";
                        target.style.height = "100%";
                        target.style.top = "0";
                        target.style.left = "50%";
                        target.style.transform = "translate(-50%, 0)";
                    }
                    target.style.maxWidth = "none";
                    target.style.maxHeight = "none";
                    target.style.position = "absolute";

                    document.getElementById("videoContainer").appendChild(target);
                }
            };
            const playerDiv = document.getElementById("videoContainer");
            const countdownElement = this.createCountdownElement(playerDiv);
            const countdownTime = 3; // Set the countdown time in seconds

            this.startCountdown(countdownElement, countdownTime, (shouldNotStart) => {
                countdownElement.remove();
                this.isCountdownInProgress = false;
                if (!shouldNotStart) {
                    startRecording();
                } else {
                    this.isCountdownStopped = false;
                }
            });
        },

        toggleActive() {
            this.isActive = !this.isActive;
        },

        stop() {
            if (this.isRecordingVideo) {
                this.player.record().stop();
            } else {
                this.stopCountdown();
            }
        },

        reset() {
            this.loading = false;
            this.shootDone = false;
            this.isActive = false;
            this.validated = false;
            this.toRecord = true;
            const imgResult = document.getElementById("imgresult");
            if (imgResult) {
                imgResult.remove();
            }
            if (this.type == "photo") {
                document
                    .getElementById("videoContainer")
                    .removeChild(document.getElementById("imgresult"));
            }
            this.resetting = true;
            this.player.record().reset();
            this.player.record().loadOptions(this.options.plugins.record);
            if (this.deviceId !== "default") {
                this.player.record().setVideoInput(this.deviceId);
            }
            if (this.audioDeviceId !== "default") {
                this.player.record().setAudioInput(this.audioDeviceId);
            }
            this.player.record().getDevice();
            //this.deviceId = "default";
            this.remainingDuration = this.formatTime(
                this.options.plugins.record.maxLength
            );
            if (this.player) {
                this.player.record().reset();
            }
        },

        formatTime(time) {
            var hr = ~~(time / 3600);
            var min = ~~((time % 3600) / 60);
            var sec = time % 60;
            var sec_min = "";
            if (hr > 0) {
                sec_min += "" + hrs + ":" + (min < 10 ? "0" : "");
            }
            sec_min += "" + min + ":" + (sec < 10 ? "0" : "");
            sec_min += "" + sec;
            return sec_min;
        },

        swipeCamera() {
            if (this.options.plugins.record.video.facingMode == "user") {
                this.options.plugins.record.video.facingMode = "environment";
            } else {
                this.options.plugins.record.video.facingMode = "user";
            }
            this.player.record().reset();
            this.player.record().loadOptions(this.options.plugins.record);
            this.player.record().getDevice();
        },

        updateDevices(event) {
            this.isModalSettings = false;
            this.deviceId = event.video;
            this.audioDeviceId = event.audio;
            this.player.record().setVideoInput(this.deviceId);
            this.player.record().setAudioInput(this.audioDeviceId);
        },

        dataURLtoBlob(dataurl) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {
                type: mime
            });
        },
        computeRecorderSize() {
            let width = window.innerWidth;
            let height = window.innerHeight;
            let computedWidth = width;
            let computedHeight = height;
            if (width > height) {
                computedWidth = (height * 60) / 100;

                computedHeight = computedWidth;
            } else {
                computedHeight = (width * 90) / 100;
                computedWidth = computedHeight;
            }
            if (this.$refs.videoContainer) {
                this.$refs.videoContainer.style.width =
                    Math.round(computedWidth / 10) * 10 + "px";
                this.$refs.videoContainer.style.height =
                    Math.round(computedHeight / 10) * 10 + "px";
            }
        },
        async getCapabilities(device) {

            let constraint = (constraint = {
                video: {
                    facingMode: {
                        exact: this.options.plugins.record.video.facingMode
                    },
                },
            });
            if (device) {
                constraint = {
                    video: {
                        facingMode: {
                            exact: this.options.plugins.record.video.facingMode
                        },
                    },
                };
            }

            const stream = await navigator.mediaDevices.getUserMedia(constraint);

            stream.getVideoTracks().forEach((track) => {
                const capabilities = track.getCapabilities();
                if (
                    this.options.plugins.record.frameWidth != capabilities.width.max ||
                    this.options.plugins.record.frameHeight != capabilities.height.max
                ) {
                    this.options.plugins.record.frameWidth = capabilities.width.max;
                    this.options.plugins.record.frameHeight = capabilities.height.max;
                    this.options.plugins.record.image.width = capabilities.width.max;
                    this.options.plugins.record.image.height = capabilities.height.max;

                    this.player.record().reset();
                    this.player.record().loadOptions(this.options.plugins.record);
                    this.player.record().getDevice();
                }
            });
        },
        async uploadContrib() {
            console.log("uploadContrib() called");
            this.$store.dispatch("updateStatus", 6);
            let blob = this.player.recordedData;
            this.player.pause();
            let datas = {};
            if (this.type == 'video') {
                datas['duration'] = this.player.record().getDuration();
            }
            datas['blob'] = blob;
            datas['signedUrl'] = this.metadatas.signedUrl;
            datas['config'] = {
                headers: { 'Content-Type': 'application/octet-stream' }
            };
            console.log("setup request done");
            this.$store.dispatch("postContrib", datas);
        },

        setUpPlayer() {
            this.player = videojs("#recorderVideo", this.options, () => {
                // print version information at startup
                const msg =
                    "Using video.js " +
                    videojs.VERSION +
                    " with videojs-record " +
                    videojs.getPluginVersion("record");
                videojs.log(msg);
                this.player.record().reset();
                this.isCameraAccessRequired = true;

                this.player.record().getDevice();
            });
            this.player.on("enumerateReady", () => {
                this.devices = this.player.record().devices;

            });
            // device is ready
            this.player.on("deviceReady", () => {
                this.player.record().enumerateDevices();
                this.isCameraError = false;
                this.isCameraAccessRequired = false;
            });

            // user clicked the record button and started recording
            this.player.on("startRecord", () => {
                this.isRecordingVideo = true;
            });

            // user completed recording and stream is available
            this.player.on("finishRecord", () => {
                // the blob object contains the recorded data that
                // can be downloaded by the user, stored on server etc.
                this.shootDone = true;
                this.isRecordingVideo = false;
                this.$store.dispatch("updateStatus", 5);
                if (this.type == "video") {
                    this.player.controls(true);

                    let blobUrl = window.URL.createObjectURL(this.player.recordedData);
                    this.player.record().load(blobUrl);
                }
            });

            this.player.on("timestamp", (event) => {
                let duration =
                    (this.player.allTimestamps[this.player.allTimestamps.length - 1] -
                        this.player.allTimestamps[0]) /
                    1000;
                let remainingDuration =
                    this.options.plugins.record.maxLength - duration;
                this.remainingDuration = this.formatTime(Math.round(remainingDuration));
            });
            // error handling
            this.player.on("error", (element, error) => {
                console.warn(error);
            });
            this.player.on("deviceError", () => {
                console.error("device error:", this.player.deviceErrorCode);
                this.isCameraError = true;

            });
        },
    },
    mounted() {
        this.noDevicesFound = false;
        this.isChromeIOS = navigator.userAgent.match("CriOS");
        if (!this.player) {
            this.computeRecorderSize();
            window.addEventListener("resize", () => {
                this.computeRecorderSize();
            });
            this.options.plugins.record.maxLength = 30;
            this.setUpPlayer();
        }
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    computed: {
        showSuccess() {
            return this.$store.state.contribSent;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style src="./index.css"></style>
