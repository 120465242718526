<template>
  <section style="background-color: #3457dd; height: 100vh;">
    <b-loading v-model="isLoading">

      <div v-if="progress == 0"
        style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <img style="width: 60px; margin-right: 10px; vertical-align: center" alt="Recorder Loading"
          src="../assets/loading.gif" /> <br> <span style="color: white; font-weight: bold;">{{ $t('wait') }}</span>
      </div>
      <div v-else
        style="background-color: #ffffff !important; padding: 25px; border-radius: 10px; margin: 0 auto !important;">
        <div style="display: flex; justify-content: center;">
          <span style="color: white">{{ $t('waitUpload') }}</span>
        </div>
        <b-progress type="is-info" :max="100" :value="progress" show-value size="is-medium" style="margin: 20px">
        </b-progress>
      </div>

    </b-loading>
    <Landing  v-if="$store.state.contribStatus === 'created' && metadatas" :metadatas="metadatas" :incentive="incentive" />
    <Recorder v-if="$store.state.contribStatus === 'ongoing' && metadatas" :terms="terms" :metadatas="metadatas" />
    <Error v-if="$store.state.contribStatus === 'alreadySent'" />
    <Success v-if="$store.state.contribStatus === 'sent'" :metadatas="metadatas" />
  </section>
</template>

<script>
// @ is an alias to /src
import Recorder from "@/components/recorder.vue";
import Landing from "@/components/landing.vue";
import Error from "@/components/error.vue";
import Success from "@/components/success.vue";
import imgBG from "@/assets/background_pictos.svg";
import { mapState } from "vuex";

export default {
  name: "RecorderWrapper",
  components: {
    Recorder,
    Landing,
    Error,
    Success,
  },
  async beforeMount() {
    this.$store.commit("setExternalId", this.$route.params.extId);
    this.$store.commit("setLanguage", {
      lang: this.$route.query.lang,
    });
    this.$root.$i18n.locale = this.$route.query.lang
      ? this.$route.query.lang
      : "en";
    if (
      this.$route.query.stars &&
      this.$route.query.stars !== "" &&
      this.$route.query.stars >= 0 &&
      this.$route.query.stars <= 5
    ) {
      this.$store.dispatch("updateStars", parseInt(this.$route.query.stars));
    }
    this.$store.dispatch("getTermsAndMetadatas", {});
    this.incentive = this.$route.query.incentive
  },
  computed: {
    ...mapState(["contribStatus", "progress"]),
    terms() {
      return this.$store.state.terms;
    },
    metadatas() {
      const metas = this.$store.state.metadatas;
      if (
        metas &&
        this.$route.query.stars &&
        this.$route.query.stars !== "" &&
        this.$route.query.stars >= 0 &&
        this.$route.query.stars <= 5
      ) {
        metas.stars = parseInt(this.$route.query.stars);
      }
      return metas;
    },
  },
  watch: {
    contribStatus(newValue) {
      this.isLoading = !newValue || newValue === "sending";
    },
  },
  data() {
    return {
      imgBG,
      isLoading: true,
      incentive: "",
    };
  },
};
</script>
