<template>
  <section style="padding-top: 100px">
    <div style="margin: 0 auto" class="tile is-ancestor">
      <div class="tile is-12" style="justify-content: center">
        <div class="tile is-4" style="max-width: 470px">
          <article class="tile is-child notification"
            style="background-color: white; color: #757575 !important; border-radius: 20px;">
            <!-- <div style="text-align: center">
              <img v-if="metadatas.sellerLogo && metadatas.sellerLogo != ''" style="width: 100px;"
                :src="metadatas.sellerLogo" alt="Logo Merchant" />
              <div v-else></div>
            </div> -->
            <br />

            <h2 style="text-align: center !important">
              {{ $t("thanks") }}
            </h2> <br />
            <div style="padding-top: 25px; padding-bottom: 20px">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-64x64">
                    <img style="border-radius: 5px" :src="metadatas.purchaseCoverUrl" />
                  </figure>
                </div>
                <div class="media-content">
                  <p class="product">{{ metadatas.purchaseTitle }}</p>
                  <p class="order">
                    {{ $t("orderDate") }} {{ metadatas.purchaseDate | date }}
                  </p>
                  <!-- <div v-if="rate == null" class="rating-btn_container bold">
                    <b-rate v-model="rate" :disabled="!changeStars" style="margin: 0 !important;"></b-rate>
                    <small class="rating-btn" v-if="!changeStars" @click="changeStars = true">
                      <small>
                        [{{ $t("rate") }}]
                      </small>
                    </small>
                  </div>
                  <div v-else class="rating-btn_container">
                    <b-rate v-model="rate" :disabled="!changeStars" style="margin: 0 !important;"></b-rate>
                    <small class="rating-btn" v-if="!changeStars" @click="changeStars = true">
                      <small>
                        [{{ $t("edit") }}]
                      </small>
                    </small>
                  </div> -->
                </div>
              </div>
            </div>
            <br />
            <p class="website" style="text-align: center !important">
              {{ $t("publish") }}<br />
              <a :href="metadatas.websiteUrl">{{ metadatas.websiteUrl }}</a>
            </p>
            <br />
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "success",
  filters: {
    date: (value) => {
      if (!value) return "";
      return new Date(value).toLocaleDateString();
    },
  },
  props: {
    metadatas: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rate: this.metadatas.stars,
      loading: false,
      changeStars: false,
    };
  },

  watch: {
    rate(value) {
      this.changeStars = false;
      this.$store.dispatch("updateStars", value);
      this.$buefy.toast.open({
        message: this.$t('rateModif'),
        type: "is-warning",
        position: "is-top-right",
      });
    },
  },
};
</script>

<style src="./index.css"></style>