export default {
  "errorAlready": "Ya se grabó un video testimonial para este pedido",
  'errorNavigator': "La función de grabación no funciona con este navegador. Prueba con otro navegador o importa un archivo.",
  'confirm': 'confirmo',
  'send': 'enviar',
  'valid': 'validar',
  'retry': 'reintentar',
  'thanks': '¡Gracias por su testimonio en video!',
  'publish': 'Tu testimonio se publicará en breve el',
  'edit': 'editar mi nota',
  'rate': 'agregar una nota',
  'orderDate': 'pedido el',
  'audioSource': 'fuente de audio',
  'videoSource': 'fuente de video',
  'back': 'atrás',
  'save': 'guardar',
  'noDevicesFound': 'No se encontraron cámaras',
  'noDevicesFoundMessage': 'Verifique que su cámara esté bien conectada y que tenga acceso autorizado a su cámara, luego actualice la página',
  'refresh': 'actualizar',
  'wait': 'Espere por favor',
  'waitUpload': 'Video en proceso de descarga, por favor espera...',
  'rateModif': 'Calificación guardada',
  'alertCam': '¡Ups! No tenemos acceso a tu cámara y/o micrófono 😕 Necesitamos que permitas el acceso a tu cámara y micrófono en tu navegador para poder grabar tu opinión en video 🙂 ¡No te preocupes! En cualquier momento, podrás modificar los permisos nuevamente yendo a la configuración de tu navegador.',
  'autorisation': 'Para grabar tu opinión en video, necesitamos que permitas el acceso a tu cámara y micrófono en tu navegador. ¡No te preocupes! En cualquier momento, podrás modificar los permisos nuevamente yendo a la configuración de tu navegador.',
  'welcome': 'Bienvenido',

  // RECORDER
  'landingRecorderTitle': "Deja una reseña en video y gana 15€",
  'landingRecorderTitleIncentive': "Ayuda a los compradores dejando tu reseña en video.",
  'landingText1': "Comparte tu experiencia sinceramente en video y contribuye a un internet más seguro. Los usuarios de internet pueden comprar con confianza gracias a ti.",
  'landingText2': "Esta reseña en video está protegida por un tercero de confianza que cumple con todos los estándares de protección de datos. Puedes expresarte con confianza.",
  'RGPD': "RGPD",
  'validation': "Seguro",
  'imgRights': "Transferencia de derechos legales",
  'exemple': "Ejemplo de reseña",
  'btnRecordReview': "Grabar mi reseña en video",
  'footerRecorder': 'tercero de confianza en video.',
  'autorights': 'Autorización de derechos de imagen',
  'sampleVideo': "Ejemplo de revisión de video :",

    // NEWLANDING
    'howRate': "¿Cómo calificaría este producto?",
    'dontLike': "malo",
    'like': "excellente",
    'addComment': "Añadir un comentario",
    'addCommentBaseline': "¿Qué le ha gustado o disgustado de este producto?",
    'addPicture': "Complete su reseña con fotos",
    'addPictureBaseline': "A los compradores les resultan más útiles las imágenes que un simple comentario.",
    'addMax3Pictures': "Añadir hasta 3 fotos",
    'addVideo': "Grabe un vídeo y reciba 15 euros 15€ 🎉",
    'addVideoBaseline': "Consiga su vale de Amazon: 30 segundos para contar su experiencia o mostrar el producto.",
    'addVideoWithout': "Grabe un vídeo",
    'addVideoBaselineWithout': "Tienes 30 segundos para contar su experiencia o mostrar el producto.",
    'recordVideo': "Grabe un vídeo",
    'retour': "volver",
    'suivant': "siguiente",
    'ignorer': "omitir",
    'consent': "Al hacer clic en validar, acepto las condiciones de uso del servicio y la política de confidencialidad. Entiendo que mi opinión será publicada y compartida públicamente.",
    
}