import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        metadatas: null,
        externalId: null,
        lang: 'fr',
        terms: {},
        contribStatus: null,
        contribSent: false,
        progress: 0,
    },
    mutations: {
        setReviewMetadatas(state, metadatas) {
            state.metadatas = metadatas;
        },
        setExternalId(state, externalId) {
            state.externalId = externalId;
        },
        setLanguage(state, payload) {
            state.lang = payload.lang ? payload.lang : 'en';
        },
        setTerms(state, terms) {
            state.terms = terms;
        },
        setContribStatus(state, status) {
            state.contribStatus = status;
        },
        setContribSent(state, status) {
            state.contribSent = status;
        },
        setProgress(state, value) {
            state.progress = value;
        }

    },
    actions: {
        async getTermsAndMetadatas({ commit }) {
            if (!this.state.externalId) {
                return
            }
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/recorder/${this.state.externalId}`);
                if (result?.status == 200) {
                    commit('setReviewMetadatas', result.data.metadatas);
                    commit('setTerms', result.data.terms);
                    commit('setContribStatus', 'created');
                }
            } catch (e) {
                commit('setContribStatus', 'alreadySent');
            }
        },

        async postContribOld({ commit }, contrib) {
            commit('setContribStatus', 'sending');
            const formData = new FormData()
            formData.append('file', contrib)
            await axios.post(`${process.env.VUE_APP_API_URL}/recorder/${this.state.externalId}`, formData, {});
            commit('setContribStatus', 'sent');
            commit('setContribSent', true);
        },

        async postContrib({ commit }, payload) {
            console.log("Before setting status sending");
            commit('setContribStatus', 'sending');
            console.log("After setting status sending");
            let config = {
                ...payload.config,
                onUploadProgress: (progressEvent) => {
                    console.log("progress: " + Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    ) + "%");
                    commit('setProgress', Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    ))
                },
            }
            console.log(payload.signedUrl);
            await axios.put(payload.signedUrl, payload.blob, config);
            console.log("After sending file");
            await axios.post(`${process.env.VUE_APP_API_URL}/recorder/${this.state.externalId}`, {
                duration: payload.duration,
            })
            console.log("After sending duration");
            commit('setContribStatus', 'sent');
            console.log("After setting status sent");
            commit('setContribSent', true);
            console.log("After setting contrib sent")
        },

        // eslint-disable-next-line no-unused-vars
        async updateStars({ commit }, stars) {
            await axios.put(`${process.env.VUE_APP_API_URL}/recorder/review/${this.state.externalId}`, { "stars": stars }, {});
        },

        // eslint-disable-next-line no-unused-vars
        async updateStatus({ commit }, status) {
            await axios.put(`${process.env.VUE_APP_API_URL}/recorder/review/${this.state.externalId}`, { "status": status }, {});
        },

        // eslint-disable-next-line no-unused-vars
        async updateComment({ commit }, comment) {
            axios.put(`${process.env.VUE_APP_API_URL}/recorder/review/${this.state.externalId}`, { "comment": comment }, {});
        },

        // eslint-disable-next-line no-unused-vars
        async updateImages ({ commit }, images) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/recorder/review/${this.state.externalId}/images?count=${images.length}&types=${images.map(i => i.type).join(',')}&names=${images.map(i => i.name).join(',')}`);
            console.log("response", response)
            let promises = [];
            for(let i = 0; i < images.length; i++) {
                const config = {
                    headers: { 'Content-Type': images[i].type }
                };
                console.log(config);
                promises.push( axios.put(response.data[i], images[i], config));
            }
            let result = await Promise.all(promises);
            console.log("result", result)
            let imageUrls =images.map(i => `reviews/images/${this.state.externalId}/${i.name}`);
            console.log("imageUrls", imageUrls);
            axios.put(`${process.env.VUE_APP_API_URL}/recorder/review/${this.state.externalId}`, { "imageUrls": imageUrls }, {});
        }
        
    },
    getters: {

    }
})