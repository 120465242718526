import Vue from 'vue'
import VueRouter from 'vue-router'
import RecorderWrapper from '../views/RecorderWrapper.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/recorder/:extId',
    name: 'RecorderWrapper',
    component: RecorderWrapper
  },

]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
