export default {
  "errorAlready": "Un avis vidéo a déjà été enregistré pour cette commande",
  'errorNavigator': "La fonction d'enregistrement ne fonctionne pas avec ce navigateur. Essaye un autre navigateur ou importe un fichier.",
  'confirm': 'je confirme',
  'send': 'envoyer',
  'valid': 'valider',
  'retry': 'recommencer',
  'thanks': 'Merci pour votre avis vidéo !',
  'publish': 'Votre avis sera publié très prochainement sur ',
  'edit': 'modifier ma note',
  'rate': 'attribuer une note',
  'orderDate': 'commandé le',
  'audioSource': 'source audio',
  'videoSource': 'source video',
  'back': 'retour',
  'save': 'sauvegarder',
  'noDevicesFound': 'Aucune caméra trouvée',
  'noDevicesFoundMessage': 'Vérifiez que votre caméra est bien connectée et que vous avez autorisé l\'accès à votre caméra puis rafraichissez la page',
  'refresh': 'rafraichir',
  'wait': 'Merci de patienter',
  'waitUpload': 'Video en cours de téléchargement, merci de patienter...',
  'rateModif': 'Note enregistrée',
  'alertCam': 'Oups ! Nous n’avons pas accès à votre caméra ni/ou à votre micro 😕 Nous avons besoin que vous autorisiez l’accès à votre caméra et à votre micro dans votre navigateur afin d’enregistrer votre avis vidéo 🙂 Pas d’inquiétude ! A tout moment, vous pourrez modifier de nouveau les accès en vous rendant dans les paramètres de votre navigateur.',
  'autorisation': 'Pour enregistrer votre avis vidéo nous avons besoin que vous autorisiez l’accès à votre caméra et à votre micro dans votre navigateur. Pas d’inquiétude ! A tout moment, vous pourrez modifier de nouveau les accès en vous rendant dans les paramètres de votre navigateur.',
  'welcome': 'Bienvenue',

  // RECORDER
  'landingRecorderTitle': "Laissez un avis vidéo et gagnez 15€",
  'landingRecorderTitleIncentive': "Aidez les acheteurs en laissant votre avis vidéo.",
  'landingText1': "Partagez votre expérience avec sincérité en vidéo et contribuez à un internet plus sûr. Les internautes peuvent acheter en toute confiance grâce à vous.",
  'landingText2': "Cet avis vidéo est protégé par un tiers de confiance respectant toutes les normes sur la protection des données. Vous pouvez vous exprimer en toute confiance.",
  'RGPD': "RGPD",
  'validation': "Sécurisé",
  'imgRights': "Cession de droit légale",
  'exemple': "Exemple d'avis",
  'btnRecordReview': "Enregistrer mon avis vidéo",
  'footerRecorder': 'tiers de confiance vidéo.',
  'autorights': "Autorisation de droit à l'image",
  'sampleVideo': "Exemples d'avis vidéo :",

    // NEWLANDING
    'howRate': "Comment évaluez vous ce produit ?",
    'dontLike': "j'aime pas",
    'like': "j'adore",
    'addComment': "Ajouter un commentaire",
    'addCommentBaseline': "Qu'est-ce que vous avez aimé ou n'avez pas aimé dans ce produit ?",
    'addPicture': "Complétez votre avis avec des photos",
    'addPictureBaseline': "Les acheteurs trouvent les images plus utiles qu’un simple commentaire.",
    'addMax3Pictures': "Ajouter jusqu'à 3 photos",
    'addVideo': "Enregistrez une vidéo et recevez 15€ 🎉",
    'addVideoBaseline': "Obtenez votre bon d'achat Amazon : 30 secondes pour raconter votre expérience ou montrer le produit.",
    'addVideoWithout': "Enregistrez une vidéo",
    'addVideoBaselineWithout': "Vous avez 30 secondes pour raconter votre expérience ou montrer le produit.",
    'recordVideo': "Enregistrez une vidéo",
    'retour': "retour",
    'suivant': "suivant",
    'ignorer': "ignorer",
    'consent': "En cliquant sur valider, j’accepte les conditions d’utilisation du service et la politique de confidentialité. Je comprends que mon avis sera publié et partagé publiquement.",
    
}