<template>
  <section style="padding-top: 100px">
    <div style="margin: 0 auto" class="tile is-ancestor">
      <div class="tile is-12" style="display:flex;justify-content: center">
        <div class="tile is-4" style="max-width: 470px">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification" style="background-color: white; border-radius: 20px">
              <span style="display: flex;justify-content: center;"><img style="width: 200px"
                  src="../../src/assets/getinshoot-reviews.png" /></span>
              <p style="text-align: center; font-weight: bold; color: #372774">
                {{ $t("errorAlready") }}
              </p>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "error",

  data() {
    return {};
  },
};
</script>

<style src="./index.css"></style>