export default {
  "errorAlready": "A video review has already been recorded for this order",
  'errorNavigator': "The recording function does not work with this browser. Try another browser or import a file.",
  'confirm': 'I confirm',
  'send': 'send',
  'valid': 'confirm',
  'retry': 'start again',
  'thanks': 'Thank you for your video review !',
  'publish': 'Your review will be published shortly on ',
  'edit': 'edit my note',
  'rate': 'give a rating',
  'orderDate': 'ordered on',
  'audioSource': 'audio source',
  'videoSource': 'video source',
  'back': 'back',
  'save': 'save',
  'noDevicesFound': 'No camera found',
  'noDevicesFoundMessage': 'Check that your camera is connected and that you have allowed access to your camera and then refresh the page',
  'refresh': 'refresh',
  'wait': 'Please wait',
  'waitUpload': 'Your video is downloading, please wait...',
  'rateModif': 'Rate saved',
  'alertCam': 'Oops! We don’t have access to your camera and/or microphone 😕 We need you to authorize access to your camera and microphone in your browser in order to record your video review 🙂 No worries! You can change the access at any time by going to your browser settings.',
  'autorisation': 'Welcome to our website! To record your video review we need you to authorize access to your camera and microphone in your browser. Don’t worry! You can change the access at any time by going to your browser settings.',
  'welcome': 'Hello',

  // RECORDER
  'landingRecorderTitle': "Leave a video review and win €15",
  'landingRecorderTitleIncentive': "Help buyers by leaving your video review.",
  'landingText1': "Share your experience sincerely in video and contribute to a safer internet. Internet users can buy with confidence thanks to you.",
  'landingText2': "This video review is protected by a trusted third party that complies with all data protection standards. You can express yourself with confidence.",
  'RGPD': "GDPR",
  'validation': "Secure",
  'imgRights': "Legal rights transfer",
  'exemple': "Example review",
  'btnRecordReview': "Record my video review",
  'footerRecorder': 'trusted video third party.',
  'autorights': 'Image rights authorization',
  'sampleVideo': "Samples video reviews :",

    // NEWLANDING
    'howRate': "How would you rate this product?",
    'dontLike': "very poor",
    'like': "excellent",
    'addComment': "Add a comment",
    'addCommentBaseline': "What did you like or dislike about this product?",
    'addPicture': "Complete your review with photos",
    'addPictureBaseline': "Buyers find images more useful than just a comment.",
    'addMax3Pictures': "Add up to 3 photos",
    'addVideo': "Record a video and receive €15 🎉",
    'addVideoBaseline': "Get your Amazon voucher: 30 seconds to talk about your experience or show the product.",
    'addVideoWithout': "Record a video",
    'addVideoBaselineWithout': "You have 30 seconds to talk about your experience or show the product.",
    'recordVideo': "Record a video",
    'retour': "back",
    'suivant': "next",
    'ignorer': "ignore",
    'consent': "By clicking confirm, I agree to the Terms of Service and Privacy Policy. I understand that my review will be published and shared publicly.",
    

}